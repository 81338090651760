import React, { useContext, useEffect, useState } from 'react'
import {
  BodyText,
  TextInput,
  PasswordInput,
  PrimaryButton,
  SecondaryButton,
  Spinner,
  ToastNotification,
  HeadingLarge,
  HeadingMedium,
} from '@hm-group/fabric-components'
import { getTouchPoint, getkeyvalueText } from '../../../util/helperfunctions'
import { EVENTACTION, EVENTOUTCOME, EVENTTYPE, PAGE, TRAFFICSOURCE, otpStatus } from '../../../util/constants'
import { AnalyticsContext } from '../../../context/analyticsContext'
import SessionStorageService from '../../../Services/SessionStorageService'
import { useLocation, useHistory } from 'react-router-dom'
import './OtpLogin.scss'

import HMlogo from '../../../assets/images/hm_logo_black.svg'
import CosLogo from '../../../assets/images/cos_new_black_logo.svg'
import WeekDayLogo from '../../../assets/images/weekday_logo_black.svg'
import MonkiLogo from '../../../assets/images/monki_logo_black.svg'
import OtherStoriesLogo from '../../../assets/images/and_other_stories_logo_black.svg'
import ArketLogo from '../../../assets/images/arket_logo_black.svg'
import hm_home_black_logo from '../../../assets/images/hm_home_black_logo.svg'
import { sendapotpemail, sendloginotpemail, verifyapotp, verifyloginotp } from '../../../Services/DataService'
import useTimer from '../../TimerHook/TimerHook'
import { UserContext } from '../../../context/userContext'
import { CustomerContext } from '../../../context/customerDetailsContext'

const OtpLogin = ({ handleLogin }) => {
  const [otp, setOtp] = useState('')
  const location = useLocation()
  const history = useHistory()
  const { lslKeyValue, updateLslKeyValue } = useContext(UserContext)
  const { updateCustomer } = useContext(CustomerContext)
  const { plainres, lslKeyData } = lslKeyValue
  const {
    emailid = '',
    countrycode = '',
    language = '',
    brandid = '',
    apbrandid = '',
    apcountrycode = '',
    urlExpired = '',
    verifiedCustomer = '',
    vtag = '',
    caseidentifier = '',
    bpid = '',
    expiresOn = '',
    aprequesttype = '',
  } = plainres || {}


  const [disableSendTimer, setDisableSendTimer] = useState(false)
  const [codeSent, setCodeSent] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [showError, setShowError] = useState(false)
  const [otpVerificationStatus, setOtpVerificationStatus] = useState('')
  const [showBanner, setShowBanner] = useState(false)
  const [isApiFailed, setIsApiFailed] = useState(false)
  const [isVisible, setIsVisible] = useState(false);
  const isshowkey = false // temporary
  const { analyticsData, setAnalyticsData, updateAnalyticsData } = useContext(AnalyticsContext)
  const { timeLeft, startTimer, stopTimer } = useTimer(() => setDisableSendTimer(false))

  const isApLogin = location.pathname == '/aplogin'
  let isAddExtract = aprequesttype

  const handleStart = () => {
    startTimer(60)
    setDisableSendTimer(true)
  }

  const handleStop = () => {
    stopTimer()
    setDisableSendTimer(false)
  }

  const handleToastClose = () => {
    setIsVisible(false);
  };


  const setLocalStorage = async () => {
    localStorage.setItem('vtag', vtag)
    localStorage.setItem('userName', emailid)
    localStorage.setItem('isLoggedIn', true)
    localStorage.setItem('current_country', countrycode)
  }

  useEffect(async () => {
    if (emailid) {
      const initialAnalyticsData = {
        emailId: emailid,
        sourceBPID: '',
        sourceCountry: isApLogin ? apcountrycode : countrycode,
        sourceBrand: isApLogin ? apbrandid : brandid,
        sourceCustomerType: '',
        preferredLanguage: language,
        eventTouchPoint: getTouchPoint(),
        trafficSource: isApLogin ? TRAFFICSOURCE.apLogin : TRAFFICSOURCE.myAccount, // later will add csp type here
      }

      setAnalyticsData(initialAnalyticsData)
      SessionStorageService.setItem('analyticsData', initialAnalyticsData)
      if (!isApLogin) {
        if (verifiedCustomer && !urlExpired) {
          await setLocalStorage()
          history.push({ pathname: '/dashboard' })
          updateAnalyticsData({
            ...initialAnalyticsData,
            eventType: EVENTTYPE.overview,
            page: PAGE.overviewPage,
            eventAction : EVENTACTION.landOnOverviewPage,
            eventOutcome : EVENTOUTCOME.success,
          })
        } else if (verifiedCustomer && urlExpired) {
          updateAnalyticsData({
            ...analyticsData,
            eventType: EVENTTYPE.login,
            page: PAGE.loginPage,
            eventAction: EVENTTYPE.landOnMessagePage,
            eventOutcome: EVENTOUTCOME.success,
          })
        }
      }
      if(!verifiedCustomer || isApLogin){
        updateAnalyticsData({
          ...initialAnalyticsData,
          eventType: isApLogin ? EVENTTYPE.apLogin : EVENTTYPE.login,
          page: isApLogin ? isAddExtract ? PAGE.apAddLoginPage : PAGE.apLoginPage : PAGE.loginPage,
          eventAction : isApLogin ? EVENTACTION.LoginApPageDataReady : EVENTACTION.landOnLoginPage,
          eventOutcome : EVENTOUTCOME.success,
        })
      }
    }
  }, [emailid, countrycode, language, brandid, apbrandid, apcountrycode, verifiedCustomer, urlExpired])


  // TODO: Move this to a separate component
  const renderAllBrandsLogo = () => {
    return (
      <div className="brand-images spacing">
        <img src={HMlogo} alt="HM" height={10}></img>
        <img src={CosLogo} alt="cos" height={10}></img>
        <img src={WeekDayLogo} alt="weekday" height={10}></img>
        <img src={MonkiLogo} alt="monki" height={10}></img>
        <img src={hm_home_black_logo} alt="HM home" height={10}></img>
        <img src={OtherStoriesLogo} alt="other stories" height={10}></img>
        <img src={ArketLogo} alt="arket" height={10}></img>
      </div>
    )
  }

  const handleOtpChange = (e) => {
    setOtp(e.target.value)
  }

  const sendAnalyticsData = (eventOutcome, eventAction) => {
    updateAnalyticsData({
      ...analyticsData,
      eventType: isApLogin ? EVENTTYPE.apLogin : EVENTTYPE.login,
      page: isApLogin ? isAddExtract ? PAGE.apAddLoginPage : PAGE.apLoginPage : PAGE.loginPage,
      eventAction,
      eventOutcome,
    })
  }

  const handleSendCodeBtn = async () => {
    handleStop()
    const payload = {
      countryCode: countrycode,
      brandId: brandid,
      language: language,
      bpid: isApLogin ? bpid : 'dummy',
      caseIdentifier: isApLogin ? caseidentifier : 'dummy',
      emailid: emailid,
      msgsentwithaddressLabel: getkeyvalueText('Verification Email', 'msgsentwithaddressLabel', false, lslKeyData),
      contactcsifntrequestLabel: getkeyvalueText('Verification Email', 'contactcsifntrequestLabel', false, lslKeyData),
      contactcsifntrequestLabel1: getkeyvalueText(
        'Verification Email',
        'contactcsifntrequestLabel1',
        false,
        lslKeyData,
      ),
      verificationcodesigninLabel: getkeyvalueText('Sign in to MPD', 'verificationcodesigninLabel', false, lslKeyData),
      verificationcodeLabel: getkeyvalueText('Sign in to MPD', 'verificationcodeLabel', false, lslKeyData),
    }
    setShowLoader(true)
    setIsApiFailed(false)
    setShowBanner(false)
    setShowError(false)
    setOtp('')

    try {
      const sendOtpResponse = isApLogin ? await sendapotpemail(payload) : await sendloginotpemail(payload)

      if (sendOtpResponse?.status === 200) {
        setShowLoader(false)
        setCodeSent(true)
        handleStart()
        setShowBanner(true)
        setIsVisible(true);
        setTimeout(() => {
          setIsVisible(false)
        }, 5000)
        sendAnalyticsData(EVENTOUTCOME.success, isApLogin ? EVENTACTION.LoginAPOtpSent : EVENTACTION.loginOTPSent)
      } else {
        sendAnalyticsData(EVENTOUTCOME.failure, isApLogin ? EVENTACTION.LoginAPOtpSent : EVENTACTION.loginOTPSent)
        setIsApiFailed(true)
        setShowBanner(true)
        setIsVisible(true);
      }
    } catch (error) {
      setShowLoader(false)
      setIsApiFailed(true)
      setShowBanner(true)
      setIsVisible(true);
      sendAnalyticsData(EVENTOUTCOME.failure, isApLogin ? EVENTACTION.LoginAPOtpSent : EVENTACTION.loginOTPSent)
    }
  }

  const getEventAction = (status) => {
    if (status == '002') {
      return isApLogin ? isAddExtract ? EVENTACTION.LoginApAddVerifiedOtpError : EVENTACTION.LoginApVerifiedOtpError : EVENTACTION.loginOTPVerifiedError
    } else {
      return isApLogin ? isAddExtract ? EVENTACTION.LoginApAddVerifiedOtp : EVENTACTION.LoginApVerifiedOtp : EVENTACTION.loginOTPVerified
    }
  }

  const handleVerifyCodeBtn = async (e) => {
    const verifyOtpPayload = isApLogin
      ? {
          emailid: emailid,
          countryCode: countrycode,
          brandId: brandid,
          caseIdentifier: caseidentifier,
          otp: otp,
          bpid: bpid,
        }
      : {
          emailid: emailid,
          countrycode: countrycode,
          brandid: brandid,
          otp: otp,
          data: 'dummy',
        }

    if (otp && otp !== '') {
      setShowLoader(true)
      setOtpVerificationStatus('')
      try {
        const verifyOtpResponse = isApLogin
          ? await verifyapotp(verifyOtpPayload)
          : await verifyloginotp(verifyOtpPayload)

        if (verifyOtpResponse && verifyOtpResponse.status === 200) {
          setShowLoader(false)
          sendAnalyticsData(EVENTOUTCOME.success, getEventAction(verifyOtpResponse.data.otpStatus))
        }
        if (verifyOtpResponse && verifyOtpResponse.data) {
          const isOtpVerified =
            verifyOtpResponse.data.otpStatus === otpStatus.correctOtp ||
            verifyOtpResponse.data.otpStatus === otpStatus.alreadyVerified

          // success case
          setOtpVerificationStatus(isOtpVerified ? EVENTOUTCOME.success : EVENTOUTCOME.failure)
          if (isOtpVerified) {
            handleStop()
            await setLocalStorage()
            handleLogin(emailid)

            if (!verifiedCustomer) {
              updateLslKeyValue({ isNewUser: true })
            }
            if(isApLogin){
              updateCustomer({ 
                businessPartnerId : bpid,
                emailAddress : emailid,
                corporateBrandId : apbrandid,
                countryCode: apcountrycode,
                apDownloadDataExpiresOn : expiresOn,
                caseidentifier: caseidentifier,
               })
            }
            
            isApLogin
              ? history.push({ pathname: '/downloadPersonalData', state: { isApLogin: true } })
              : history.push({ pathname: '/dashboard' })

          } else {
            setShowError(true)
          }
        } else {
          sendAnalyticsData(EVENTOUTCOME.failure, getEventAction(verifyOtpResponse.data.otpStatus))
          setIsApiFailed(true)
          setShowBanner(true)
          handleStop()
        }
      } catch (e) {
        setShowLoader(false)
        setIsApiFailed(true)
        setShowBanner(true)
        handleStop()
        console.error('Error during OTP verification:', e) // Log the error details

        sendAnalyticsData(
          EVENTOUTCOME.failure,
          isApLogin ? EVENTACTION.LoginApVerifiedOtpError : EVENTACTION.loginOTPVerifiedError,
        )
      }
    } else {
      setShowError(true)
    }
  }

  const renderLoader = () => {
    return (
      <div className="loader_section">
        <Spinner color={'black'} isRendered />
      </div>
    )
  }

  const renderUrlExpired = () => {
    // updateAnalyticsData({
    //   ...analyticsData,
    //   eventType: EVENTTYPE.login,
    //   page: PAGE.loginPage,
    //   eventAction: EVENTACTION.landOnLoginPage,
    //   // eventOutcome: EVENTOUTCOME.success,
    // })
    // sendAnalyticsData(EVENTOUTCOME.success, EVENTTYPE.landOnMessagePage)
    return (
      <>
       <HeadingLarge Tag="h1" alignment="center" className="spacing">
            {getkeyvalueText('login', 'mpd_heading', isshowkey, lslKeyData)}
          </HeadingLarge>

        <HeadingMedium Tag="h1" alignment="center" className="spacing" tabIndex={1}>
          {getkeyvalueText('LinkExpired', 'linkexpiredheader', isshowkey, lslKeyData)}
        </HeadingMedium>
        <BodyText alignment="center" Tag="p" lineClamp="1" isPreFormatted className="spacing">
          {getkeyvalueText('LinkExpired', 'linkexpiredmessage', isshowkey, lslKeyData)}
        </BodyText>
      </>
    )
  }

  const renderAPUrlExpired = () => {
    return (
      <>
        <HeadingLarge Tag="h1" alignment="center" className="spacing">
          {getkeyvalueText('login', 'mpd_heading', isshowkey, lslKeyData)}
        </HeadingLarge>

        <HeadingMedium alignment="center" Tag="p" lineClamp="1" isPreFormatted className="spacing">
          {getkeyvalueText('A&PSessionExpiredPage', 'a&pdataexpiredLabel', isshowkey, lslKeyData)}
        </HeadingMedium>
        <BodyText alignment="center" Tag="p" lineClamp="1" isPreFormatted className="spacing">
          {getkeyvalueText('A&PSessionExpiredPage', 'a&pdataavailableLabel', isshowkey, lslKeyData)}
        </BodyText>
        <BodyText alignment="center" Tag="p" lineClamp="1" isPreFormatted className="spacing">
          {getkeyvalueText('A&PSessionExpiredPage', 'a&pnewcopyLabel', isshowkey, lslKeyData)}
        </BodyText>
      </>
    )
  }



  return (
    <>
     {urlExpired === true ? (isApLogin ? renderAPUrlExpired() : renderUrlExpired() ): null}
      {!urlExpired  && (isApLogin || !verifiedCustomer) ? (
        <div className="login__form_box" style={{ padding: '0 22px' }}>
          <HeadingLarge Tag="h1" alignment="center" className="spacing">
            {getkeyvalueText('login', 'mpd_heading', isshowkey, lslKeyData)}
          </HeadingLarge>

         
          {/* login form */}
          {urlExpired ===false && (isApLogin || verifiedCustomer === false) ? (
            <div>
              <HeadingLarge Tag="h1" alignment="center" className="spacing">
                {getkeyvalueText('A&PSigninPage', 'a&psigninLabel', isshowkey, lslKeyData)}
              </HeadingLarge>
              {codeSent ? (
                <div>
                  <BodyText alignment="center" Tag="p" lineClamp="1" className="spacing">
                    {getkeyvalueText('login', 'custemaillinksentLabel', isshowkey, lslKeyData)}
                  </BodyText>
                  <BodyText alignment="center" Tag="p" lineClamp="1" className="spacing">
                    {getkeyvalueText('Lightboxes', 'custtoentercodeLabel', isshowkey, lslKeyData)}
                  </BodyText>
                </div>
              ) : (
                <BodyText alignment="center" Tag="p" lineClamp="1" className="spacing">
                  {getkeyvalueText('A&PSigninPage', 'a&pverifycodeLabel', isshowkey, lslKeyData)}
                </BodyText>
              )}
              <div className="input__wrapper">
                <TextInput
                  isDisabled
                  id="inputEmail"
                  data-testid="inputEmail"
                  value={emailid}
                  label={getkeyvalueText('login', 'userLabel', isshowkey, lslKeyData)}
                />
              </div>
              {codeSent && (
                <div>
                  <PasswordInput
                    id="inputotp"
                    onChange={(e) => {
                      handleOtpChange(e)
                    }}
                    className="spacing"
                    toggleVisibilityButtonLabel="toggle visibility"
                    value={otp}
                    isRequired
                    inputMode="number"
                    max={6}
                    label={getkeyvalueText('Lightboxes', 'disverificationcodeLabel', isshowkey, lslKeyData)}
                    isInvalid={otpVerificationStatus === EVENTOUTCOME.failure || (!otpVerificationStatus && showError)}
                    helperText={
                      showError &&
                      (otpVerificationStatus === EVENTOUTCOME.failure
                        ? getkeyvalueText('Lightboxes', 'discentervalidLabel', isshowkey, lslKeyData)
                        : !otp
                        ? getkeyvalueText('Lightboxes', 'discentercodeLabel', isshowkey, lslKeyData)
                        : '')
                    }
                  />
                </div>
              )}

              {disableSendTimer ? (
                <BodyText alignment="center" Tag="p" lineClamp="1" isPreFormatted>
                  {getkeyvalueText('login', 'resendemailLabel', isshowkey, lslKeyData).split('{XX}')[0]}
                  {timeLeft}
                  {getkeyvalueText('login', 'resendemailLabel', isshowkey, lslKeyData).split('{XX}')[1]}
                </BodyText>
              ) : null}

              {codeSent ? (
                <div className="loginButton">
                  <PrimaryButton
                    className="submit__btn"
                    data-testid="verify_code_btn"
                    isDisabled={showLoader}
                    id="verify_btn"
                    onClick={handleVerifyCodeBtn}
                  >
                    {getkeyvalueText('Lightboxes', 'disverifycodeLabel', isshowkey, lslKeyData)}
                  </PrimaryButton>
                  <SecondaryButton
                    className="submit__btn"
                    data-testid="send_new_code_btn"
                    id="send_newcode_btn"
                    isDisabled={showLoader || disableSendTimer}
                    onClick={handleSendCodeBtn}
                  >
                    {getkeyvalueText('Lightboxes', 'discsendcodeLabel', isshowkey, lslKeyData)}
                  </SecondaryButton>
                  {showLoader ? renderLoader() : null}
                  {renderAllBrandsLogo()}
                </div>
              ) : (
                <div>
                  <PrimaryButton
                    className="submit__btn"
                    data-testid="send_verification_code_btn"
                    isDisabled={showLoader}
                    onClick={handleSendCodeBtn}
                  >
                    {getkeyvalueText('Lightboxes', 'custcodeLabel', isshowkey, lslKeyData)}
                  </PrimaryButton>
                  {showLoader ? renderLoader() : null}
                  {renderAllBrandsLogo()}
                </div>
              )}

              {showBanner && (
                <ToastNotification
                  className="spacing"
                  autoDelete={false}
                  labels={{
                    close: 'Close'
                  }}
                  message={getkeyvalueText(
                    isApiFailed ? 'forgotpwd' : 'login',
                    isApiFailed ? 'tryagnLabel' : 'custemailsuccessLabel',
                    true,
                    lslKeyData,
                  )}
                  onClose={handleToastClose}
                  isVisible={isVisible}
                  severity={isApiFailed ? 'error' : 'success'}
                />
              )}
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
    </>
  )
}

export default OtpLogin
